import env from "../utils/universalEnv";

const trustedUrls = [
  "https://dashboard.staging.robinpowered.com/auth/saml",
  "https://dashboard.test.robinpowered.com/auth/saml",
  "https://dashboard.robinpowered.com/auth/saml",
  "https://dashboard.local.robinpowered.com/auth/saml",
  // Using env variable directly as to not import the full config.
  ...(env.ROBINBOT_REDIRECT_URI ? env.ROBINBOT_REDIRECT_URI.split(",") : []),
  ...(env.RIPPLING_REDIRECT_URI ? env.RIPPLING_REDIRECT_URI.split(",") : []),
  ...(env.CAMPUS_APP_REDIRECT_URI
    ? env.CAMPUS_APP_REDIRECT_URI.split(",")
    : []),
  ...(env.ADMIN_PORTAL_REDIRECT_URI
    ? env.ADMIN_PORTAL_REDIRECT_URI.split(",")
    : []),
];

export default function isTrustedRedirectUrl(url) {
  return !!trustedUrls.find(
    (trustedUrlPrefix) => url.indexOf(trustedUrlPrefix) === 0
  );
}
